<template>
  <div class="content-container services-container page-wrap">

    <section class="services-intro">

      <article class="services-content">
        <h1 class="heading-1">Galerie</h1>
        <p class="desc">Zveme Vás k pobytu ve wellness JUPITER. Nabízíme Vám zde pohodový pobyt, klidné prostředí a jedinečné soukromí pro relaxaci a odpočinek.</p>
        <p class="desc">Luxusně vybavený apartmán v moderní dřevostavbě s jedinečným designem interiéru a prostředím, které Vám pomůže zapomenout na starosti všedních dní. Právě zde máte možnost prožít mnoho příjemného a nezapomenutelného s tím, kdo je Vám nejbližší.</p>
        <p class="desc">Úžasnou, romantickou atmosféru doplní zvuk praskajícího dřeva v krbu i velkorysý výhled na nevšední, velmi hezké prostory venkovních zákoutí přilehlé zahrady.</p>
      </article>

      <div class="gallery-container" :class="sliderFullScreen ? 'full-screen' : ''">
        <vueper-slides ref="galleryEl" class="no-shadow" fade slide-content-outside="top" slide-content-outside-class="max-widthed"
          :touchable="false" :slide-ratio="0.3" :fixed-height="!sliderFullScreen" :bullets="false" :arrows="false"
          @slide="getSlide">
          <vueper-slide v-for="currentSlide in slidesCount" :key="currentSlide" :image="require('../assets/gallery/' + currentSlide + '.jpg')">
          </vueper-slide>
        </vueper-slides>
        <div class="slider-slot" @click="openFullScreenSlider" v-show="!sliderFullScreen"></div>

        <button class="slider-close-button" @click="closeFullScreenSlider" v-show="sliderFullScreen">
          <svg class="icon" width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
            <g id="Close" transform="matrix(1,0,0,1,-265,-20)">
              <path d="M273,26.388L279.033,20.354C280.108,19.28 281.72,20.892 280.646,21.967L273.806,28.806C273.361,29.251 272.639,29.251 272.194,28.806L265.354,21.967C264.28,20.892 265.892,19.28 266.967,20.354L273,26.388ZM273,29.612L266.967,35.646C265.892,36.72 264.28,35.108 265.354,34.033L272.194,27.194C272.639,26.749 273.361,26.749 273.806,27.194L280.646,34.033C281.72,35.108 280.108,36.72 279.033,35.646L273,29.612Z"
                style="fill-rule:nonzero;" />
            </g>
          </svg>
        </button>
        <div class="arrows">
          <button class="arrow-left" @click="prev()">
            <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
              <title>arrow-left</title>
              <desc>Created with Sketch.</desc>
              <g id="2.-Služby" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="jupiter-/-services-dw-&gt;-gallery" transform="translate(-64.000000, -433.000000)" fill="#000000"
                  fill-rule="nonzero">
                  <g id="Group">
                    <g id="Group-7" transform="translate(49.000000, 416.000000)">
                      <path d="M18.4142136,25 L22.7071068,29.2928932 C23.0976311,29.6834175 23.0976311,30.3165825 22.7071068,30.7071068 C22.3165825,31.0976311 21.6834175,31.0976311 21.2928932,30.7071068 L15.2928932,24.7071068 C14.9023689,24.3165825 14.9023689,23.6834175 15.2928932,23.2928932 L21.2928932,17.2928932 C21.6834175,16.9023689 22.3165825,16.9023689 22.7071068,17.2928932 C23.0976311,17.6834175 23.0976311,18.3165825 22.7071068,18.7071068 L18.4142136,23 L32,23 C32.5522847,23 33,23.4477153 33,24 C33,24.5522847 32.5522847,25 32,25 L18.4142136,25 Z"
                        id="arrow-left"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <button class="arrow-right" @click="next()">
            <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
              <title>arrow-right</title>
              <desc>Created with Sketch.</desc>
              <g id="2.-Služby" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="jupiter-/-services-dw-&gt;-gallery" transform="translate(-1519.000000, -433.000000)" fill="#000000"
                  fill-rule="nonzero">
                  <g id="Group">
                    <g id="Group-6" transform="translate(1504.000000, 416.000000)">
                      <path d="M29.5857864,25 L16,25 C15.4477153,25 15,24.5522847 15,24 C15,23.4477153 15.4477153,23 16,23 L29.5857864,23 L25.2928932,18.7071068 C24.9023689,18.3165825 24.9023689,17.6834175 25.2928932,17.2928932 C25.6834175,16.9023689 26.3165825,16.9023689 26.7071068,17.2928932 L32.7071068,23.2928932 C33.0976311,23.6834175 33.0976311,24.3165825 32.7071068,24.7071068 L26.7071068,30.7071068 C26.3165825,31.0976311 25.6834175,31.0976311 25.2928932,30.7071068 C24.9023689,30.3165825 24.9023689,29.6834175 25.2928932,29.2928932 L29.5857864,25 Z"
                        id="arrow-right"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <span class="slides-count">{{ currentSlide }} / {{ slidesCount }}</span>
      </div>
    </section>
  </div>
</template>

<script>
import {
  VueperSlides,
  VueperSlide
} from 'vueperslides'
// import 'vueperslides/dist/vueperslides.min.css'

export default {
  name: "Gallery",
  components: {
    VueperSlides,
    VueperSlide
  },
  metaInfo: {
      title: 'Galerie',
      titleTemplate: '%s | Jupiter Wellness',
      meta: [
      {
        'property': 'og:title',
        'content': 'Galerie',
        'template': '%s | Jupiter Wellness',
        'vmid': 'og:title'
      },
      {
        'property': 'og:url',
        'content': 'https://jupiterwellness.cz/galerie',
        'vmid': 'og:url'
      },
    ]
    },
  data: function () {
    return {
      currentSlide: 1,
      slidesCount: 16,
      sliderFullScreen: false,
    };
  },
  mounted() {

  },
  methods: {
    prev() {
      this.$refs.galleryEl.previous();
    },
    next() {
      this.$refs.galleryEl.next();
    },
    getSlide(event) {
      this.currentSlide = event.currentSlide.index + 1;
    },
    openFullScreenSlider() {
      if (!this.sliderFullScreen) {
        this.sliderFullScreen = true;
      }
    },
    closeFullScreenSlider() {
      this.sliderFullScreen = false;
    }
  },
  computed: {

  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.services-container {
  @include breakpoint(desktop) {
    flex-direction: column;
  }

  .services-content {
    max-width: rem(682);
    margin: 0 auto;
  }

  .l-services-list-centering-container {
    margin: 0;

    @include breakpoint(tablet) {
      display: flex;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
    }

    @include breakpoint(desktop) {
      flex-direction: column;
    }
  }

  .services-intro {
    text-align: center;

    @include breakpoint(desktop) {
      width: 100%;

    }

    .heading-1 {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }


    .desc {
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      color: #48484a;
      margin: 0 0 rem(24) 0;

      @include breakpoint(tablet) {
        margin: 0 0 rem(36) 0;
      }
    }
  }

  .l-services-list-container {
    margin: 0 auto rem(20) auto;

    @include breakpoint(tablet) {
      display: flex;
      width: percent(600, 736);
      margin: 0 auto rem(40) auto;

    }

    @include breakpoint(desktop) {
      margin: 0 0 rem(40) 0;

    }
  }

  .services-list {
    padding: 0;
    list-style-type: none;
    text-align: left;

    &:first-child {
      @include breakpoint(tablet) {
        margin: 0 percent(60, 600) 0 0;
      }
    }

    @include breakpoint(tablet) {
      margin: 0 0 rem(40) 0;
    }

    @include breakpoint(desktop) {
      margin: 0 0 rem(48) 0;
    }

    @include breakpoint(desktop-wide) {
      margin: 0 0 rem(56) 0;
    }

    &__item {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      color: #787469;



      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 9px;
        margin-right: 0.5em;
        mask-image: url('../assets/tick.svg');
        background-color: #72ca9b;
        mask-repeat: no-repeat;
        mask-position: center center;
      }

      &.minus {
        &::before {
          mask-image: url('../assets/close.svg');
          background-color: #f47e66;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.gallery-container {
  margin: 0 auto rem(24) auto;
  max-width: rem(1080);

  @include breakpoint(tablet) {
    margin: 0 auto rem(32) auto;
  }

  @include breakpoint(desktop) {
    height: rem(560);
    overflow: hidden;
  }

  &.full-screen {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    background: #fff;
    width: 100vw;
    height: 100vh;
    z-index: $z-index-3;
    max-width: none;

    @include breakpoint(desktop) {
      padding: rem(48);
    }

    .vueperslide {
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

    .vueperslides__parallax-wrapper {
      padding-bottom: calc(100vh - #{rem(96)}) !important;
    }

    .arrows {
      position: absolute;
      right: auto;
      left: 0;
      width: 100%;
      bottom: 50%;
      z-index: $z-index-4;
      display: flex;
      justify-content: space-between;

      @include breakpoint(desktop) {
        width: calc(100% - #{rem(96)});
        left: rem(48);
      }

      .arrow-left,
      .arrow-right {
        background-color: rgba(0, 0, 0, 0.5);
        appearance: none;
        border: none;
        width: rem(48);
        height: rem(48);

        svg g {
          fill: #fff;
          color: #fff;
          stroke: #fff;
        }
      }

      .arrow-left {
        &::after {
          display: none;
        }
      }
    }

    .slides-count {
      right: auto;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: rem(14);
      z-index: 1;
      text-shadow: none;
      text-align: center;
      color: #212123;
    }
  }

  .arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    .arrow-left,
    .arrow-right {
      background-color: rgba(0, 0, 0, 0.5);
      appearance: none;
      border: none;
      width: rem(48);
      height: rem(48);
      cursor: pointer;
      transition: background-color .2s ease-in-out;

      &:hover {
              background-color: rgba(0, 0, 0, 0.7);
      }

      svg g {
        fill: #fff;
        color: #fff;
        stroke: #fff;
      }
    }

    .arrow-left {
      &::after {
        content: '';
        display: block;
        width: 1px;
        background-color: #eae8e6;
        height: rem(24);
        position: absolute;
        top: rem(12);
        right: rem(48);
      }
    }
  }

  .slides-count {
    position: absolute;
    right: rem(120);
    bottom: rem(14);
    z-index: 1;
    text-shadow: 0 0 8px #000000;
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.71;
    text-align: right;
    color: #ffffff;
  }
}

.slider-close-button {
  background-color: rgba(0, 0, 0, 0.5);
    appearance: none;
    border: none;
    width: rem(48);
    height: rem(48);
    position: absolute;
   top: 0;
  right: 0;
    z-index: $z-index-5;
    cursor: pointer;
    transition: background-color .2s ease-in-out;


  @include breakpoint(tablet) {
    top: rem(48);
    right: rem(48);
  }


    svg g {
      fill: #fff;
      color: #fff;
      stroke: #fff;
    }

  &:hover {
              background-color: rgba(0, 0, 0, 0.7);
      }

}

.slider-slot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  height: 100%;
  z-index: 1;
  cursor: pointer;

}

.vueperslides--fixed-height {
  height: rem(400);

  @include breakpoint(desktop) {
    height: rem(560);
  }
}

.experience {
  background: $background-darker;
  text-align: center;
  padding: rem(20) percent(16, 370);
  margin: 0 auto rem(20) auto;

  @include breakpoint(desktop) {
    max-width: rem(682);
  }

  .heading-2 {
    margin: 0 0 rem(10) 0;
  }

  .desc {
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #48484a;
    margin: 0 auto rem(16) auto;

    &:last-child {
      margin: 0 auto;
    }
  }
}
</style>
